import { Controller } from "@hotwired/stimulus"


export default class extends Controller {

  static targets = ["radioInput", "text", "date", "radio", "multiSelect", "dropdown"];

  connect() {
    if (this.hasShowDivTarget) {
      this.showDivTarget.style.display = "none";
    }
  }

  radioChanged() {
    this.radioInputTargets.forEach((radioInput, i) => {
      if (radioInput.checked) {
        if (this.currentlyCheckedQuestion) this.currentlyCheckedQuestion.style.display = 'none';

        if (radioInput.value == "Questions::TextQuestion") {
          if (this.dateTarget) {
            this.textTarget.style.display = "block"
            this.currentlyCheckedQuestion = this.textTarget;
          }
        }
        if (radioInput.value == "Questions::DateQuestion") {
          if (this.dateTarget) {
            this.dateTarget.style.display = "block"
            this.currentlyCheckedQuestion = this.dateTarget;
          }
        }
        if (radioInput.value == 'Questions::RadioQuestion') {
          if (this.radioTarget) {
            this.radioTarget.style.display = "block"
            this.currentlyCheckedQuestion = this.radioTarget;
          }
        }
        if (radioInput.value == 'Questions::MultiSelectQuestion') {
          if (this.multiSelectTarget) {
            this.multiSelectTarget.style.display = "block"
            this.currentlyCheckedQuestion = this.multiSelectTarget;
          }
        }
        if (radioInput.value == 'Questions::DropdownQuestion') {
          if (this.multiSelectTarget) {
            this.dropdownTarget.style.display = "block"
            this.currentlyCheckedQuestion = this.dropdownTarget;
          }
        }
      }
    });
  }
}
