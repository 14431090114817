import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["address"];
  static values = {
    acceptTermsId: String,
    termsWarningId: String,
    selectionWarningId: String,
    minDisplayPage: Number,
    maxDisplayPage: Number,
  };

  connect() {
    // Handles the event of when a bootstrap modal closes
    // https://getbootstrap.com/docs/4.0/components/modal/#events
    if (this.hasAddressTarget) {
      let page = this.minDisplayPageValue;
      while (page <= this.maxDisplayPageValue) {
        let consentModal = $(`#consent-modal-${page}`);
        if (consentModal) {
          consentModal.on("hidden.bs.modal", () => {
            this.addressTarget.disabled = false;
          });
        }
        page++;
      }
    }
  }

  initializeConsentModal(e) {
    let pendingConsent = this.nextPendingConsent();

    if (pendingConsent) {
      this.addressTarget.disabled = true;
      $(`#consent-modal-${pendingConsent}`).modal();
    }
  }

  nextPendingConsent() {
    let page = this.minDisplayPageValue;
    let result;

    while (page <= this.maxDisplayPageValue) {
      if (this.isRequiredConsentPendingInPage(page)) {
        result = page;
        break;
      }
      page++;
    }

    return result;
  }

  isRequiredConsentPendingInPage(page) {
    let result = false;
    let consents = [
      ...document.querySelectorAll(`[data-display-page="${page}"]`),
    ];

    if (consents.length > 0) {
      consents.forEach((element) => {
        let agreeInput = element.querySelector('input[value="yes"]');
        let disagreeInput = element.querySelector('input[value="no"]');

        if (!agreeInput.checked && !disagreeInput.checked) {
          result = true;
        } else if (
          element.dataset.requiredToContinue === "true" &&
          !agreeInput.checked
        ) {
          result = true;
        }
      });
    }
    return result;
  }

  isNotSelectedConsentsInPage(page) {
    let result = false;
    let consents = [
      ...document.querySelectorAll(`[data-display-page="${page}"]`),
    ];

    if (consents.length > 0) {
      consents.forEach((element) => {
        let agreeInput = element.querySelector('input[value="yes"]');
        let disagreeInput = element.querySelector('input[value="no"]');

        if (!agreeInput.checked && !disagreeInput.checked) {
          result = true;
        }
      });
    }
    return result;
  }

  updateDate(event) {
    let id = event.target.dataset.requirementId;
    let dateField = document.getElementById(
      `consent_requirement_${id}_consentment_date`
    );
    if (dateField) {
      dateField.value = new Date().toUTCString();
    }
  }

  updateTermsWarning() {
    let page = this.minDisplayPageValue;
    while (page <= this.maxDisplayPageValue) {
      let termsWarningAlert = document.getElementById(
        `${this.termsWarningIdValue}-${page}`
      );
      if (termsWarningAlert) {
        termsWarningAlert.hidden = !this.isNotSelectedConsentsInPage(page);
      }
      page++;
    }
  }

  updateSelectionWarning() {
    let page = this.minDisplayPageValue;
    while (page <= this.maxDisplayPageValue) {
      let selectionWarningAlert = document.getElementById(
        `${this.selectionWarningIdValue}-${page}`
      );
      if (selectionWarningAlert) {
        selectionWarningAlert.hidden =
          this.isNotSelectedConsentsInPage(page) ||
          !this.isRequiredConsentPendingInPage(page);
      }
      page++;
    }
  }

  updateAcceptTerms() {
    let page = this.minDisplayPageValue;
    while (page <= this.maxDisplayPageValue) {
      let acceptTermsButton = document.getElementById(
        `${this.acceptTermsIdValue}-${page}`
      );
      if (acceptTermsButton) {
        acceptTermsButton.disabled = this.isRequiredConsentPendingInPage(page);
      }
      page++;
    }
  }

  updateAgreementTexts(event) {
    let id = event.target.dataset.requirementId;
    let agreementText = event.target.dataset.agreementText;
    let disagreementText = event.target.dataset.disagreementText;

    let agreementTextField = document.getElementById(
      `consent_requirement_${id}_agreement_text`
    );
    let disagreementTextField = document.getElementById(
      `consent_requirement_${id}_disagreement_text`
    );

    if (agreementText) {
      agreementTextField.value = agreementText;
      disagreementTextField.removeAttribute("value");
    }

    if (disagreementText) {
      disagreementTextField.value = disagreementText;
      agreementTextField.removeAttribute("value");
    }
  }

  consentClicked(event) {
    this.updateDate(event);
    this.updateAcceptTerms();
    this.updateTermsWarning();
    this.updateSelectionWarning();
    this.updateAgreementTexts(event);
  }

  acceptTermsClicked(event) {
    let consentModal = $(
      `#consent-modal-${event.target.dataset.displayPageIndex}`
    );
    if (consentModal) {
      consentModal.modal("hide");
    }

    let pendingConsent = this.nextPendingConsent();
    if (pendingConsent) {
      let nextConsentModal = $(`#consent-modal-${pendingConsent}`);
      nextConsentModal.modal("show");
    }
  }
}
