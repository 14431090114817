import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["reminderMessage", 'icon'];
  static values = { selectedStatus: String };

  connect() {
    this.updateReminderMessageVisibility();
  }

  selectedStatusValueChanged(event) {
    if(event.target) {
        this.updateReminderMessageVisibility(event.target.value);
    }
    else {
        this.updateReminderMessageVisibility()
    }
  }

  updateReminderMessageVisibility(selectedValue) {
    if(!selectedValue) {
        selectedValue = this.selectedStatusValue;
    }

    if (selectedValue === "ATS: Screening Scheduled" || selectedValue === "ATS: Enrolled") {
      this.reminderMessageTarget.style.display = "flex";
    } else {
      this.reminderMessageTarget.style.display = "none";
    }
  }
}
