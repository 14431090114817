import BaseUtmSource, { chartStore } from "./base_utm_source_controller";

export default class extends BaseUtmSource {

  static targets = ['legend'];

  drawChartFromLegend(event) {
    $(".dropdown-item").removeClass("active");
    $(this.scope.element).addClass("active");

    let existingChart = chartStore.chart;
    if (existingChart) existingChart.destroy();

    let chart = document.getElementById('utm-source-chart');
    this.drawChart(chart, this.datasets());
  }
}
