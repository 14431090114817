import { Controller } from "@hotwired/stimulus"
import Rails from '@rails/ujs';
import { Loader } from "@googlemaps/js-api-loader"

export default class extends Controller {
  static targets = [
    "address",
    "form",
    "latitude",
    "longitude",
    "city",
    "region",
    "country",
    "postalCode",
    "addressLine1",
    "display"
  ];

  static values = {
    clientFacing: String,
    apiKey: String,
  }

  connect() {
    const button = document.getElementsByClassName("location-submit")[0];
    if (button && this.clientFacingValue === "true") {
      button.disabled = true;
    }

    const loader = new Loader({
      apiKey: this.apiKeyValue,
      version: "weekly"
    });

    loader.importLibrary('places').then(() => {
      this.initializeAutocomplete()
    });
  }

  disconnect() {
    const element = this.addressTarget;
    if (element) {
      $(element).remove();
    }
  }

  initializeAutocomplete() {
    const element = this.addressTarget;
    if (element) {
      let autocompleteOptions;

      if (this.clientFacingValue === "true") {
        autocompleteOptions = { types: ['(regions)'], componentRestrictions: { country: 'AR' } };
      } else {
        autocompleteOptions = { types: ['geocode'] };
      }

      this.autocomplete = new google.maps.places.Autocomplete(element, autocompleteOptions);

      this.autocomplete.addListener('place_changed', () => this._populateFullAddress());
    }

  }

  _populateFullAddress() {
    const button = document.getElementsByClassName("location-submit")[0];
    if (button) {
      button.classList.remove("btn-success");
      button.disabled = true;
    }

    const place = this.autocomplete.getPlace();
    this.postalCodeTarget.value = "";

    if (place && this.addressTarget) {
      this.addressTarget.value = place.formatted_address;

      if(this.hasDisplayTarget) {
        this.displayTarget.innerHTML = `<strong>${place.formatted_address}</strong>`
      }
      if (this.clientFacingValue !== "true") {
        this.addressLine1Target.value = place.name;
      }

      if (this.latitudeTarget && this.longitudeTarget && place.geometry) {
        this.latitudeTarget.value = place.geometry.location.lat();
        this.longitudeTarget.value = place.geometry.location.lng();
      }

      this.fillAddressComponents(place.address_components);

      // If user searches by city, a postal code is not always returned so we need to reverse geocode to get it
      if (!this.postalCodeTarget.value) {
        this.getPostalCode(place.geometry.location.lat(), place.geometry.location.lng())
          .then((postalCode) => {
            if (postalCode) {
              this.postalCodeTarget.value = postalCode;
              if (button) {
                button.disabled = false;
                button.classList.add("btn-success");
              }
            }
          });
      }

      if (this.postalCodeTarget.value) {
        if (button) {
          button.disabled = false;
          button.classList.add("btn-success");
        }
      }
    }
  }

  async getPostalCode(lat, long) {
    const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${this.apiKeyValue}`)
    const data = await response.json();

    for (const result of data.results) {
      for (const component of result.address_components) {
        if (component.types.includes("postal_code")) {
          return component.long_name;
        }
      }
    }
  }

  fillAddressComponents(addressComponents) {
    for (let addressComponentsKey in addressComponents) {
      let component = addressComponents[addressComponentsKey];
      let type_element;

      for (let typesKey in component.types) {
        switch (component.types[typesKey]) {
          case 'locality':
          case 'sublocality':
            type_element = this.cityTarget;
            break;
          case 'administrative_area_level_1':
            type_element = this.regionTarget;
            break;
          case 'country':
            if (this.countryTarget) type_element = this.countryTarget;
            break;
          case 'postal_code':
            type_element = this.postalCodeTarget;
            break;
        }

        if (type_element) {
          type_element.value = component.long_name;
        }
      }
    }
  }
}
