import { Controller } from "@hotwired/stimulus"

import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/js/utils.js';

export default class extends Controller {
  static targets = ["telInput", "validMsg", "errorMsg", "formSubmit"];
  static values = {
    phonePlaceholder: String,
    countryCode: String,
  }

  connect() {
    this.iti = intlTelInput(this.telInputTarget, {
      initialCountry: this.countryCodeValue,
      utilsScript: "intl-tel-input/build/js/utils.js",
      customPlaceholder: function (selectedCountryPlaceholder, selectedCountryData) {
        return this.phonePlaceholderValue || 'Enter Phone Number'
      }
    });
  }

  onSubmit = (event) => {
    if (this.error) event.preventDefault();
  }

  validate() {
    var errorMap = ["Invalid number", "Invalid country code", "Too short", "Too long", "Invalid number"];

    if (this.telInputTarget.value.trim()) {
      if (this.iti.isValidNumber()) {
        this.error = false;
        this.validMsgTarget.classList.remove("iti__hide");
      } else {
        this.error = true
        this.telInputTarget.classList.add("error");
        var errorCode = this.iti.getValidationError();
        this.errorMsgTarget.innerHTML = "X" + " " + errorMap[errorCode];
        this.errorMsgTarget.classList.remove("iti__hide");
      }
    }
  }

  onBlur() {
    this.reset();
    this.validate();
  }

  reset() {
    this.telInputTarget.classList.remove("error");
    this.errorMsgTarget.innerHTML = "";
    this.errorMsgTarget.classList.add("iti__hide");
    this.validMsgTarget.classList.add("iti__hide");

    this.formSubmitTarget.disabled = false;
  }
}
