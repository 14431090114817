import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  static targets = ['drawer'];

  toggleDrawer() {
    this.drawerTarget.classList.toggle('d-none')
  }

  submitform(event) {
    const detail = event.detail, xhr = detail[0], options = detail[1];

    Turbolinks.visit(options.url);
    event.preventDefault();
  }
}
