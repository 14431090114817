import { Controller } from "@hotwired/stimulus"

import Handlebars from 'handlebars';

export default class extends Controller {
  static targets = ['utmCampaignInput'];
  static values = {
    url: String
  }

  disconnect() {
    $(this.utmCampaignInputTarget).tagsinput('destroy');
  }

  connect() {
    let campaigns = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.whitespace,
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: `${this.urlValue}&search=%QUERY`,
        wildcard: '%QUERY',
        filter: function (data) {
          return $.map(data, function (campaign) {
            let source = campaign.utm_source || 'Unknown Source'
            let formatted_campaign = campaign.utm_campaign || 'Unknown Campaign'
            return { ...{ value: campaign.id, text: `${source} - ${formatted_campaign}` }, ...campaign };
          });
        }
      }
    });

    $(this.utmCampaignInputTarget).tagsinput({
      itemValue: 'value',
      itemText: 'text',
      allowDuplicates: false,
      freeInput: false,
      typeaheadjs: [{
        hint: false,
        highlight: true,
        minLength: 1
      }, {
        displayKey: 'text',
        freeInput: false,
        limit: Infinity,
        name: 'campaigns',
        source: campaigns,
        templates: {
          empty: '<div class="tt-suggestion tt-no-result">No results found.</div>',
          suggestion: Handlebars.compile(`
            <div class="suggestion">
              <div class="utm-campaign d-flex">{{utm_campaign}} (<div class="mx-1">{{utm_source}}</div>)</div>
            </div>
          `)
        }
      }]
    });
  }
}
