import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  static targets = ['chart'];
  static values = {
    data: Array,
    labels: Array
  }

  connect() {
    if (!document.documentElement.hasAttribute("data-turbo-preview") && this.dataValue.length > 0) {
      this.drawChart();
    }
  }

  drawChart() {
    let ctx = this.chartTarget.getContext('2d');

    new Chart(ctx, {
      type: 'line',
      data: {
        labels: this.formattedLabels(),
        datasets: this.datasets()
      },
      options: {
        responsive: true,
        elements: {
          line: {
            tension: 0
          }
        },
        legend: {
          display: true
        },
        layout: {
          padding: {
            top: 5
          }
        },
        scales: {
          yAxes: [{
            id: 'y',
            type: 'linear',
            position: 'left',
            scaleLabel: {
              display: true,
              labelString: 'Spend in $',
              fontSize: 16,
              fontStyle: 'bold'
            },
            ticks: {
              beginAtZero: true,
              padding: 15,
              fontSize: 14,
              fontStyle: 'bold',
              callback: function (value) { if (value % 1 === 0) { return value; } }
            }
          }, {
            id: 'y1',
            type: 'linear',
            position: 'right',
            gridLines: {
              drawOnChartArea: false, // only want the grid lines for one axis to show up
            },
            scaleLabel: {
              display: true,
              labelString: 'Referrals',
              fontSize: 16,
              fontStyle: 'bold'
            },
            ticks: {
              beginAtZero: true,
              callback: function (value) { if (value % 1 === 0) { return value; } }
            }
          }],
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Week',
              fontSize: 16,
              fontStyle: 'bold'
            },
            ticks: {
              padding: 15,
              fontSize: 14,
              fontStyle: 'bold'
            }
          }]
        }
      }
    })
  }

  formattedLabels() {
    const labels = this.dataValue;

    const firstWeeknum = labels[0].weeknum
    const firstYear = labels[0].year
    const lastWeeknum = labels[labels.length - 1].weeknum
    const lastYear = labels[labels.length - 1].year

    let dateArray = [];
    let currentDate = moment().year(firstYear).week(firstWeeknum).day("Monday");
    let stopDate = moment().year(lastYear).week(lastWeeknum).day("Monday");

    while (currentDate <= stopDate) {
      dateArray.push(`${currentDate.week()}-${currentDate.year()}`)
      currentDate = moment(currentDate).add(1, 'week');
    }

    return dateArray;
  }

  datasets() {
    const data = this.dataValue;
    const formattedLabels = this.formattedLabels();

    let transformedSpend = Array.apply(null, { length: formattedLabels.length }).map(function () { return 0; })
    let transformedReferrals = Array.apply(null, { length: formattedLabels.length }).map(function () { return 0; })
    data.forEach((utm_statistic) => {
      let date = `${utm_statistic.weeknum}-${utm_statistic.year}`
      let transformedIndex = formattedLabels.indexOf(date)
      if (transformedIndex > -1) {
        transformedSpend[transformedIndex] = Number(utm_statistic.spend)
        transformedReferrals[transformedIndex] = Number(utm_statistic.referred)
      }
    })

    return [{
      data: transformedSpend,
      backgroundColor: 'rgba(0, 128, 128, 1)',
      borderColor: 'rgba(0, 128, 128, 1)',
      fill: false,
      yAxisID: 'y',
      label: 'Spend'
    }, {
      data: transformedReferrals,
      backgroundColor: 'rgb(255, 99, 132)',
      borderColor: 'rgb(255, 99, 132)',
      fill: false,
      yAxisID: 'y1',
      label: 'Referrals'
    }]
  }
}
