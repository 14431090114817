import { Controller } from "@hotwired/stimulus";
import _ from "lodash";

export default class extends Controller {
  static targets = ["locationInput"];
  static values = {
    apiKey: String,
    country: String,
    language: String,
    required: Boolean,
  };

  disconnect() {
    $(this.locationInputTarget).typeahead("destroy");
    $(this.locationInputTarget).remove();
  }

  connect() {
    const button = document.getElementsByClassName("location-submit")[0];
    button.disabled = true;

    const apiKey = this.apiKeyValue;

    let locations = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace("address"),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: `https://maps.googleapis.com/maps/api/geocode/json?address=%QUERY&sensor=false&key=${apiKey}${this._countryCodeParam()}${this._languageParam()}`,
        wildcard: "%QUERY",
        filter: (locations) => {
          return locations.results.reduce((accum, item) => {
            let postal_code_component = item.address_components.find(
              (element) => element.types.includes("postal_code")
            );
            let location = this._location_data(item);
            if (postal_code_component) {
              return accum.concat({
                address: item.formatted_address,
                lat: item.geometry.location.lat,
                lng: item.geometry.location.lng,
                postal_code: postal_code_component.short_name,
                ...location,
              });
            }
            return accum;
          }, []);
        },
      },
      limit: 20,
    });

    locations.initialize();

    let searchboxTypeahead = $(this.locationInputTarget);
    let latitude_el = document.getElementById("question_latitude");
    let longitude_el = document.getElementById("question_longitude");
    let city_el = document.getElementById("question_city");
    let region_el = document.getElementById("question_region");
    let country_el = document.getElementById("question_country");
    let response_el = document.getElementById("question_response");

    // Fill in lat/lng fields and autosubmit the form
    var searchboxItemSelectedHandler = function (e, datum) {
      latitude_el.value = datum.lat;
      longitude_el.value = datum.lng;
      city_el.value = datum.city;
      region_el.value = datum.region;
      country_el.value = datum.country;
      response_el.value = datum.postal_code;

      const button = document.getElementsByClassName("location-submit")[0];
      button.classList.remove("disabled");
      button.disabled = false;
      button.classList.add("btn-success");
    };

    // Initialise typeahead
    searchboxTypeahead
      .typeahead(
        {
          highlight: true,
          autoselect: true,
          classNames: {
            hint: "tw-hidden"
          }
        },
        {
          name: "address",
          displayKey: "address",
          source: locations.ttAdapter(),
          templates: {
            empty:
              '<div class="tt-suggestion tt-no-result">No results found.</div>',
          },
        }
      )
      .on("typeahead:asyncrequest", function () {
        $("#loadingType").removeClass("d-none");
        $("#loadingType").addClass("d-inline");
      })
      .on("typeahead:asynccancel typeahead:asyncreceive", function () {
        $("#loadingType").addClass("d-none");
        $("#loadingType").removeClass("d-inline");
      });

    searchboxTypeahead.on("typeahead:selected", searchboxItemSelectedHandler);
    searchboxTypeahead.on(
      "typeahead:autocompleted",
      searchboxItemSelectedHandler
    );
    searchboxTypeahead.on("keyup", function () {
      if (searchboxTypeahead.val().length < 3) {
        const button = document.getElementsByClassName("location-submit")[0];
        if (this.requiredValue) button.disabled = true;
        button.classList.remove("btn-success");
        latitude_el.value = "";
        longitude_el.value = "";
      }
    });
    searchboxTypeahead.on("blur", function () {
      if ($(".tt-suggestion:first-child")) {
        if (
          (latitude_el.value === "" && longitude_el.value === "") ||
          (latitude_el.value === undefined && longitude_el.value === undefined)
        ) {
          $(".tt-suggestion:first-child").trigger("click");
        }
      }
    });
    searchboxTypeahead.on("typeahead:asyncreceive", function () {
      searchboxTypeahead.typeahead("open");
    });
  }

  _countryCodeParam() {
    const countryCode = this.countryValue;

    // Google needs an  ISO 3166-1 country code
    // https://en.wikipedia.org/wiki/ISO_3166-1#Current_codes
    // country codes are defined in locale yml files
    return countryCode ? `&components=country:${countryCode}` : "";
  }

  _languageParam() {
    const languageCode = this.languageValue;

    return languageCode ? `&language=${languageCode}` : "";
  }

  _location_data(item) {
    let location = {};
    item.address_components.forEach((element) => {
      // https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete-addressform
      if (
        element.types.indexOf("locality") > -1 ||
        element.types.indexOf("postal_town") > -1 ||
        element.types.indexOf("sublocality_level_1") > -1
      ) {
        location.city = element.long_name;
      }
      if (element.types.indexOf("administrative_area_level_1") > -1)
        location.region = element.long_name;
      if (element.types.indexOf("country") > -1)
        location.country = element.long_name;
    });
    return location;
  }
}
