import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  static targets = ['collapsed', 'expanded']

  expand() {
    this.expandedTarget.classList.remove('hidden');
    this.expandedTarget.classList.add('show');

    this.collapsedTarget.classList.add('hidden');
    this.collapsedTarget.classList.remove('show');
  }

  collapse() {
    this.expandedTarget.classList.add('hidden');
    this.expandedTarget.classList.remove('show');

    this.collapsedTarget.classList.add('show');
    this.collapsedTarget.classList.remove('hidden');
  }
}
