import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["chart"];
  static values = {
    data: Object,
  };

  connect() {
    if (!document.documentElement.hasAttribute("data-turbo-preview")) {
      this.drawChart();
    }
  }

  drawChart() {
    const { total_count, data, colors, labels, descriptions } = this.dataValue;
    let ctx = this.chartTarget.getContext("2d");

    new Chart(ctx, {
      type: "pie",
      data: {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: colors,
          },
        ],
      },
      options: {
        plugins: {
          tooltip: {
            enabled: true,
            bodyFontSize: 14,
            footerMarginTop: 10,
            callbacks: {
              title: (context) => {
                let count = context[0].dataset.data[context[0].dataIndex];
                if (count === 0) return '0%';
                return `${Math.max(
                  1,
                  Math.ceil((count / total_count) * 100)
                )}%`;
              },
              footer: (context) => {
                let idx = context[0].dataIndex;
                let labels = descriptions[idx];
                let formattedLabels = [];
                if (labels) {
                  for (let label in labels) {
                    formattedLabels.push(
                      `${labels[label]} (${this._percentage(
                        labels[label],
                        total_count
                      )}) - ${label || "Not Specified"}`
                    );
                  }
                }
                return formattedLabels;
              },
            },
          },
        },
      },
    });
  }

  _percentage(num, total_count) {
    return Number(num / total_count).toLocaleString(undefined, {
      style: "percent",
    });
  }
}
