import BaseRankedAnalysis from './base_ranked_analysis_controller';

export default class extends BaseRankedAnalysis {

  static targets = ['chart'];

  connect() {
    if (!document.documentElement.hasAttribute("data-turbo-preview")) {
      this.drawChart(this.chartTarget, this.datasets());
    }
  }
}
