import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  static targets = ['map'];
  static values = {
    country: String,
    drawInfoMarkers: Boolean,
    drawLegend: Boolean,
    localizeCenter: Boolean,
    sites: Array,
    zoom: Number
  }

  connect() {
    if (typeof (google) != "undefined") {
      this.initializeMap()
    }
  }

  initializeMap() {
    this.drawMap();
  }

  drawMap() {
    if (this._map == undefined) {
      this._map = new google.maps.Map(this.mapTarget, {
        center: { lat: 34.499766, lng: -35.934795 },
        zoom: this.zoomValue || 1
      })
      this._drawMarkers();
      if (this.localizeCenterValue) this._localizeCenter(this._map);
      if (this.drawLegendValue) this._drawCutsomLegend();
    }
    return this._map;
  }

  _drawMarkers() {
    if (this._map == undefined) return;

    this.sitesValue.forEach((site) => {
      const siteLatLng = new google.maps.LatLng(site.latitude, site.longitude);
      const marker = new google.maps.Marker({
        position: siteLatLng,
        map: this._map,
        title: site.name
      });
      if (this.drawInfoMarkersValue) {
        this._drawInfoMarkers(marker, site);
        this._conncectRowClick(marker, site);
      }
    })
  }

  _drawCutsomLegend() {
    const legendDiv = document.getElementsByClassName('expanded-legend')[0];
    legendDiv.classList.remove('hidden')
    legendDiv.classList.add('show')
  }

  _drawInfoMarkers(marker, site) {
    const infoWindow = new google.maps.InfoWindow({
      content: this._contentString(site),
    });
    marker.addListener("click", () => {
      if (this.activeInfoWindow != null) this.activeInfoWindow.close();
      infoWindow.open(this._map, marker);
      this.activeInfoWindow = infoWindow
    });
  }

  _conncectRowClick(marker, site) {
    const tr = document.getElementById(`study-site-${site.id}`);
    if (tr) {
      google.maps.event.addDomListener(tr, "click", function () {
        google.maps.event.trigger(marker, "click");
      })
    }
  }

  _contentString(site) {
    const contactString = site.primary_contact_email
      ? `<p>${site.primary_contact_name}<br>` +
      `${site.primary_contact_email}</p>`
      : 'Not Provided'
    const addressString = site.address &&
      '<p><strong>Address</strong></p>' +
      `<p>${site.address}<br>` +
      `${site.city}, ${site.region} ${site.postal_code}</p>`

    const contentString =
      '<div id="markerContent">' +
      `<h5 id="firstHeading" class="firstHeading">${site.name}</h5>` +
      '<div id="bodyContent">' +
      '<div class="row">' +
      '<div class="col-md-6">' +
      '<p><strong>Contact</strong></p>' +
      contactString +
      '</p>' +
      '</div><br>' +
      '<div class="col-md-6">' +
      addressString +
      "</div>" +
      "</div>" +
      "</div>" +
      "</div>";

    return contentString;
  }

  _localizeCenter(map) {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({
      componentRestrictions: {
        country: this.countryValue,
      }
    }, function (results, status) {
      if (status == google.maps.GeocoderStatus.OK) {
        map.setCenter(results[0].geometry.location);
        map.setZoom(6)
      }
    });
  }
}
