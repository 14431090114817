import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["input", "deleteButton"];

    updateButtonState() {
        const confirmationInput = this.inputTarget.value.trim().toLowerCase();
        const isConfirmationValid = confirmationInput === "delete patient";
        if (isConfirmationValid) {
            this.deleteButtonTarget.classList.remove("disabled");
        } else {
            this.deleteButtonTarget.classList.add("disabled");
        }
    }
}
