import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  static targets = ['modal'];

  connect() {
    $('#updateUtmSourcesModal').on('hidden.bs.modal', function() {
      document.getElementById("utm-source-change-count").innerHTML = ''
      document.getElementById("utm-source-ids-container").innerHTML = ''
    })
  }

  fillModalContent() {
    const ids = this.checkboxTableController.values;

    const count = document.createTextNode(ids.length);
    const element = document.getElementById("utm-source-change-count")
    element.appendChild(count)

    const idsEl = document.createTextNode(ids.join(', '))
    const idsContainer = document.getElementById("utm-source-ids-container")
    idsContainer.appendChild(idsEl)

    const screenerAttemptsEl = document.getElementById('screener_attempt_ids_hidden_field')
    screenerAttemptsEl.value = ids.toString()
  }

  hideModal() {
    $(this.modalTarget).modal('hide');
  }

  get checkboxTableController() {
    return this.application.getControllerForElementAndIdentifier(document.getElementById("checkbox-table"), "checkbox-table")
  }
}
