import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "length"];
  static values = {
    maxLength: Number
  }

  connect() {
    const length = this.inputTarget.value.length
    this.checkLengthLimit(length)
  }

  update(e) {
    const length = e.target.value.length
    this.checkLengthLimit(length)
  }

  checkLengthLimit(length) {
    this.lengthTarget.innerHTML = `${length}/${this.maxLengthValue}`

    if (length === this.maxLengthValue) {
      this.lengthTarget.classList.add("tw-text-red-500")
    } else {
      this.lengthTarget.classList.remove("tw-text-red-500")
    }
  }
}
