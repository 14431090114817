import { Controller } from "@hotwired/stimulus"


export default class CheckboxTableController extends Controller {
  static targets = ['all', 'item'];

  connect() {
    this.values = []
  }

  toggleAll = () => {
    const checked = this.allTarget.checked;
    this.values = []
    this.itemTargets.forEach((target, idx) => {
      target.checked = checked;
      if (checked) this.values.push(target.value)
    })
    this._toggleButton();
  }

  toggleItem = (e) => {
    const checked = e.target.checked;
    if (e.target.checked) {
      this.values.push(e.target.value)
    } else {
      this.values = this.values.filter(el => el != e.target.value)
    }
    this._toggleButton();
    this._checkSelected();
  }

  _checkSelected = () => {
    var all = this.allTarget;
    var total = this.itemTargets.length;
    var len = this.itemTargets.filter(checkbox => checkbox.checked);
    all.checked = len === total;
  }

  _toggleButton = () => {
    const button = document.getElementById('update-utm-sources-button')
    if (button) {
      if (this.values.length > 0) {
        button.classList.remove('d-none')
        button.classList.add('d-inline')
      } else {
        button.classList.remove('d-inline')
        button.classList.add('d-none')
      }
    }
  }
}
