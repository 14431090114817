import { Controller } from "@hotwired/stimulus"
import { zxcvbn, zxcvbnOptions } from '@zxcvbn-ts/core'
import * as zxcvbnCommonPackage from '@zxcvbn-ts/language-common'
import * as zxcvbnEnPackage from '@zxcvbn-ts/language-en'

const STRENGTH = {
  0: "Too weak",
  1: "Weak",
  2: "Needs improvement",
  3: "Almost there!",
  4: "Strong 💪"
}


const OPTIONS = {
  translations: zxcvbnEnPackage.translations,
  graphs: zxcvbnCommonPackage.adjacencyGraphs,
  dictionary: {
    ...zxcvbnCommonPackage.dictionary,
    ...zxcvbnEnPackage.dictionary,
  },
}

zxcvbnOptions.setOptions(OPTIONS)

export default class extends Controller {
  static targets = [ "password", "meter", "strength", "suggestions" ]

  determineStrength() {
    const password = this.passwordTarget.value
    const result = zxcvbn(password)
    this.meterTarget.value = result.score
    this.strengthTarget.textContent = STRENGTH[result.score]
    this.suggestionsTarget.innerHTML = ""

    if (result.feedback.suggestions.length > 0 && password.length > 0) {
      for (const suggestion of result.feedback.suggestions) {
        const suggestionEl = document.createElement('li')
        suggestionEl.innerHTML = suggestion
        this.suggestionsTarget.appendChild(suggestionEl)
      }
    }
  }
}