// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import '@stimulus/polyfills';
import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))

document.addEventListener('turbo:before-cache', function () {
  application.controllers.forEach(function (controller) {
    if (typeof controller.teardown === 'function') {
      controller.teardown();
    }
  });
});
