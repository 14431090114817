import { Controller } from "@hotwired/stimulus";
import moment from "moment";

export default class extends Controller {
  static targets = [
    "statusPicker",
    "statusSection",
    "newCommentField",
    "formButtons",
    "statusUpdateField",
    "statusUpdateButtons",
    "statusUpdateConfirmButton",
    "optionalUpdateCommentBox",
    "newCommentBox",
    "statusHeader",
    "datetimePicker",
    "submitButton",
    "newCommentForm",
    "popupBox",
  ];

  connect() {
    this.originalStatus = this.statusPickerTarget.value;
    this.trixToolbar = this.newCommentFieldTarget.querySelector("trix-toolbar");
    this.trixToolbar.classList.add("tw-hidden");

    const lcStatus = document.getElementById("lc-status").innerText;
    if(this.isATS()) {
      this.showAtsSection();
    }

    document.addEventListener("trix-change", () => this.newCommentBoxChanged());
  }

  newCommentBoxClicked() {
    this.statusSectionTarget.classList.add("tw-hidden");
    this.formButtonsTarget.classList.add("tw-flex");
    this.formButtonsTarget.classList.remove("tw-hidden");
    this.trixToolbar.classList.remove("tw-hidden");
    this.trixToolbar.classList.add("tw-block");
    this.statusHeaderTarget.classList.add("tw-hidden");
  }

  newCommentBoxChanged() {
    if (this.newCommentBoxTarget.value.length >= 1) {
      this.enableButton(this.submitButtonTarget);
    } else {
      this.disableButton(this.submitButtonTarget);
    }
  }

  statusPickerClicked() {
    if (this.statusPickerTarget.value === "Appointment Scheduled") {
      this.datetimePickerTarget.classList.remove("tw-hidden");
      this.datetimePickerTarget.classList.add("tw-block");
    }

    this.newCommentFieldTarget.classList.add("tw-hidden");
    this.statusUpdateButtonsTarget.classList.add("tw-flex");
    this.statusUpdateButtonsTarget.classList.remove("tw-hidden");
    this.trixToolbar.classList.add("tw-hidden");
    this.trixToolbar.classList.remove("tw-block");
  }

  patientStatusChanged() {
    if (this.statusPickerTarget.value === "ATS: Screening Scheduled" ||
        this.statusPickerTarget.value === "ATS: Enrolled" ||
        this.statusPickerTarget.value === "Assigned to Site" ||
        this.statusPickerTarget.value === "ATS: Screened - Awaiting Updates" ) {
      this.datetimePickerTarget.classList.remove("tw-hidden");
      this.datetimePickerTarget.classList.add("tw-block");
      this.disableButton(this.statusUpdateConfirmButtonTarget);
    } else {
      this.datetimePickerTarget.classList.remove("tw-block");
      this.datetimePickerTarget.classList.add("tw-hidden");

      if (this.statusPickerTarget.value !== this.originalStatus) {
        this.statusUpdateFieldTarget.value = `Updated patient's status from <b>${this.originalStatus}</b> to <b>${this.statusPickerTarget.value}</b>`;
        this.enableButton(this.statusUpdateConfirmButtonTarget);
      } else {
        this.disableButton(this.statusUpdateConfirmButtonTarget);
      }
    }
  }

  appointmentTimeChanged() {
    const datetimeUTC = moment(this.datetimePickerTarget.value).format();
    this.statusUpdateFieldTarget.value = `
      Updated patient's status from <b>${this.originalStatus}</b> to <b>${
        this.statusPickerTarget.value
      }</b>
      <br>
      Date: ${moment(datetimeUTC).format(
        "MMM D, Y",
      )} 
    `;

    this.enableButton(this.statusUpdateConfirmButtonTarget);
  }

  statusUpdateConfirmed() {
    this.newCommentFieldTarget.classList.remove("tw-hidden");
    this.newCommentFieldTarget.classList.add("tw-block");
    this.newCommentBoxTarget.setAttribute("placeholder", "Add a comment (optional) or click submit...");
    this.newCommentBoxClicked();
    this.enableButton(this.submitButtonTarget);
  }

  submitClicked() {
    this.originalStatus = this.statusPickerTarget.value;
    this.newCommentFormTarget.requestSubmit();
    this.cancelButtonClicked();
    this.disableButton(this.submitButtonTarget);
    if (this.originalStatus === "Assigned to Site" || this.originalStatus.startsWith("ATS:")) {
      this.showAtsSection();
    }
    if (this.isDNQNI(this.originalStatus)) {
      this.showDNQNIPopup();
    }
  }

  cancelButtonClicked() {
    this.statusUpdateFieldTarget.value = "";
    this.newCommentBoxTarget.value = "";
    this.newCommentBoxTarget.setAttribute("placeholder", "Add a comment...");

    this.datetimePickerTarget.value = "";
    this.datetimePickerTarget.classList.remove("tw-block");
    this.datetimePickerTarget.classList.add("tw-hidden");

    this.statusHeaderTarget.classList.remove("tw-hidden");

    this.formButtonsTarget.classList.remove("tw-flex");
    this.formButtonsTarget.classList.add("tw-hidden");

    this.statusUpdateButtonsTarget.classList.remove("tw-flex");
    this.statusUpdateButtonsTarget.classList.add("tw-hidden");

    this.statusSectionTarget.classList.remove("tw-hidden");
    this.statusSectionTarget.classList.add("tw-flex");

    this.newCommentFieldTarget.classList.remove("tw-hidden");
    this.newCommentFieldTarget.classList.add("tw-block");

    this.trixToolbar.classList.remove("tw-block");
    this.trixToolbar.classList.add("tw-hidden");

    this.disableButton(this.submitButtonTarget);

    this.statusPickerTarget.value = this.originalStatus;
  }
  
  disableButton(buttonEl) {
    buttonEl.disabled = true;
    buttonEl.classList.remove("tw-bg-lc-pink1", "tw-text-white");
    buttonEl.classList.add("tw-bg-lc-grey1", "tw-text-lc-grey3");
  }
  
  enableButton(buttonEl) {
    buttonEl.disabled = false;
    buttonEl.classList.add("tw-bg-lc-pink1", "tw-text-white");
    buttonEl.classList.remove(
      "tw-bg-lc-grey1",
      "tw-text-lc-grey3",
    );
  }

  showAtsSection(content) {
    document.getElementById("ats-section").classList.remove("tw-hidden");
  }

  isATS() {
    return document.getElementById("lc-status").innerText === "Assigned to Site" || document.getElementById("lc-status").innerText.startsWith("ATS:") ;
  }

  isDNQNI(statusText) {
    return statusText.includes("DNQ") || statusText.includes("Interest")
  }

  showDNQNIPopup() {
    this.popupBoxTarget.classList.remove('tw-hidden');
  }

  closeDNQNIPopup() {
    this.popupBoxTarget.classList.add('tw-hidden');
  }
}
