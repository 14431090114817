import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    query: String,
  };

  setCustomValidity() {
    let answers = document.querySelectorAll(`[id^="${this.queryValue}"]`);
    if (answers) {
      answers.forEach((answer) => {
        answer.setCustomValidity("");
      });
    }
  }
}
