import BaseRankedAnalysis, { rankedAnalysisStore } from './base_ranked_analysis_controller';

export default class extends BaseRankedAnalysis {

  static targets = ['legend'];

  drawChartFromLegend(event) {
    $(".dropdown-item").removeClass("active");
    $(this.scope.element).addClass("active");

    let existingChart = rankedAnalysisStore.chart;
    if (existingChart) existingChart.destroy();

    let chart = document.getElementById('ranked-analysis-chart');
    this.drawChart(chart, this.datasets());
  }
}
