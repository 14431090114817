import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["noneOfTheAbove", "option", "checkbox"];

  selectNone() {
    this.optionTargets.forEach((target, idx) => {
      target.checked = false;
    })
  }

  selectOption() {
    if (this.noneOfTheAboveTarget.checked) {
      this.noneOfTheAboveTarget.checked = false
    }
  }

  toggleCheckbox() {
    this.checkboxTarget.checked = !this.checkboxTarget.checked;
  }
}
