import { Controller } from "@hotwired/stimulus"

import consumer from '../channels/consumer';
import { Reflex } from "src/reflex";

export default class extends Controller {
  static values = {
    studySlug: String
  }

  connect() {
    this.subscribeToExportNotifications();
    this.reflex = new Reflex('ExportNotificationsReflex');
  }

  disconnect() {
    this.exportNotificationsChannel.unsubscribe();
    this.reflex.disconnect();
  }

  subscribeToExportNotifications() {
    this.exportNotificationsChannel = consumer.subscriptions.create({
      channel: 'ExportNotificationsChannel',
      study_slug: this.studySlugValue
    }, {
      received: function (data) {
        this.reflex.stimulate(data);
      }.bind(this)
    });
  }
}
