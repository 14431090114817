import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  static targets = ['chart'];
  static values = {
    data: Object
  }

  connect() {
    if (!document.documentElement.hasAttribute("data-turbo-preview")) {
      this.drawChart();
    }
  }

  drawChart() {
    let ctx = this.chartTarget.getContext('2d');

    window.boxplot = new Chart(ctx, {
      type: 'boxplot',
      data: {
        labels: [
          ['Referred to', 'Reached by Leapcure', `n = ${this.dataValue.referred_to_reached.length}`],
          ['Reached by Leapcure','to Assigned to Site', `n = ${this.dataValue.reached_to_assigned.length}`],
          ['Assigned to Site to', 'Updated by Site Coordinator', `n = ${this.dataValue.assigned_to_updated.length}`]
        ],
        datasets: [{
          backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(75, 192, 192, 0.2)'],
          borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)', 'rgba(75, 192, 192, 1)'],
          label: 'Referred to Reached',
          borderWidth: 2,
          outlierColor: '#999999',
          padding: 10,
          itemRadius: 0,
          data: [this.dataValue.referred_to_reached, this.dataValue.reached_to_assigned, this.dataValue.assigned_to_updated]
        }]
      },
      options: {
        responsive: true,
        indexAxis: 'y',
        coef: 0,
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: true,
            callbacks: {
              title: function(context) {
                return context[0].label.split(',').slice(0,2).join(' ')
              },
              label: function(context) {
                let labels = []
                labels.push(
                  `Min: ${context.formattedValue.whiskerMin}`,
                  `Q1: ${context.formattedValue.q1}`,
                  `Median: ${context.formattedValue.median}`,
                  `Mean: ${context.formattedValue.mean}`,
                  `Q3: ${context.formattedValue.q3}`,
                  `Max: ${context.formattedValue.whiskerMax}`,
                )
                return labels
              }
            }
          }
        },
        scales: {
          x: {
            grid: {
              display: false
            },
            title: {
              display: true,
              text: 'Days',
              font: {
                size: 14,
                weight: 'bold'
              },
            },
            beginAtZero: true
          },
          y: {
            ticks: {
              grid: {
                display: false
              },
              font: {
                size: 14
              }
            }
          }
        }
      }
    })
  }
}
