import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["notification"];

  disconnect() {
    $(this.notificationTarget).remove();
  }

  connect() {
    if (!document.documentElement.hasAttribute("data-turbo-preview")) {
      this.showFlashNotifications();
    }
  }

  showFlashNotifications() {
    const element = this.notificationTarget;

    $.notify({
      message: element.textContent,
    }, {
      type: element.dataset.notificationClass
    });
  }
}
