import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["radioInput", "text", "date", "radio", "multiSelect", "dropdown"]

  connect() {
    if (this.hasShowDivTarget) {
      this.showDivTarget.style.display = "none"
    }
  }

  radioChanged() {
    const QUESTION_TYPE_TARGETS = {
      "Questions::TextQuestion": this.hasTextTarget ? this.textTarget : null,
      "Questions::DateQuestion": this.hasDateTarget ? this.dateTarget : null,
      "Questions::RadioQuestion": this.hasRadioTarget ? this.radioTarget : null,
      "Questions::MultiSelectQuestion": this.hasMultiSelectTarget ? this.multiSelectTarget : null,
      "Questions::DropdownQuestion": this.hasDropdownTarget ? this.dropdownTarget : null
    }

    this.radioInputTargets.forEach((radioInput) => {
      if (radioInput.checked) {
        if (this.currentlyCheckedQuestion) {
          this.currentlyCheckedQuestion.style.display = 'none'
        }

        const target = QUESTION_TYPE_TARGETS[radioInput.value]
        if (target) {
          target.style.display = "block"
          this.currentlyCheckedQuestion = target
        }
        this.submitForm()
      }
    })
  }

  submitForm() {
    const form = this.element.closest("form")
    if (form) {
      form.submit()
    }
  }
}
