import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';
import { Controller } from "@hotwired/stimulus"

import { fetch } from 'whatwg-fetch';
import { csrfToken } from "@rails/ujs";

export default class extends Controller {
  static targets = ['textField', 'remoteOptionList'];
  static values = {
    url: String,
    uuid: String
  }

  search = _event => {
    this.userInput = this.textFieldTarget.value;
    this.currentIdx = -1;
    if (this.userInput !== '') {
      this._abortPreviousRequest();
      this.abortController = new AbortController();
      let url = this.urlValue + '&limit=10';
      this.abortableFetch(url, {
        signal: this.abortController.signal,
        method: "POST",
        body: JSON.stringify({
          search: this.userInput,
        }),
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken(),
        },
      })
        .then((response) => response.json())
        .then((response) => this._updateRemoteOptionList(response.html));
    } else {
      this._updateRemoteOptionList('');
    }
  }

  // Use native browser implementation if it supports aborting, otherwise
  // fall back to fetch polyfill.
  // https://github.com/github/fetch#aborting-requests
  abortableFetch = (url, options) => {
    const fn = ('signal' in new Request('')) ? window.fetch : fetch;
    return fn(url, options);
  }

  handleKeys = event => {
    if (this._isNavigationKey(event.keyCode)) {
      this._navigateInSearchResult(event.keyCode);
    } else if (event.keyCode === this.constructor.KeyEnter && this.currentIdx > -1) {
      this._selectCurrentRemoteOption(event);
    } else if (event.keyCode === this.constructor.KeyEscape) {
      this._cancelRemoteOptionList();
    }
  }

  remoteOptionClicked = event => {
    this.userInput = event.currentTarget.dataset.value;
    this.textFieldTarget.value = event.currentTarget.dataset.display;
    this.textFieldTarget.focus();
    this.remoteOptionListTarget.innerHTML = '';

    const idElement = document.getElementById('selected_id_hidden_field')
    const buttonEl = document.getElementById('remote-select-button')
    if (idElement) {
      idElement.value = event.currentTarget.dataset.value
      if (buttonEl) buttonEl.disabled = false
    } else if (this.uuidValue) {
      const uuidElement = document.getElementsByClassName(this.uuidValue)[0]
      if (uuidElement) uuidElement.value = this.userInput
    }
  }

  handleBlur = () => {
    this.hideRemoteOptionList()
  }

  showRemoteOptionList = () => this.remoteOptionListTarget.classList.remove('hidden');

  hideRemoteOptionList = () => this.remoteOptionListTarget.classList.add('hidden');

  preventBlur = mouseDownEvent => mouseDownEvent.preventDefault();

  _abortPreviousRequest = () => {
    if (this.abortController) {
      this.abortController.abort();
    }
  }

  _updateRemoteOptionList = html => {
    this.remoteOptionListTarget.innerHTML = html;
    this.showRemoteOptionList();
  }

  _isNavigationKey = keyCode => {
    return keyCode === this.constructor.KeyCodeUp
      || keyCode === this.constructor.KeyCodeDown;
  }

  _navigateInSearchResult = keyCode => {
    let remoteOptionContainers = this.remoteOptionListTarget.children;
    if (remoteOptionContainers.length === 0) {
      return;
    }

    let previousIdx = this.currentIdx;
    if (keyCode === this.constructor.KeyCodeUp) {
      this.currentIdx = this._previousIdx(remoteOptionContainers.length);
    } else if (keyCode === this.constructor.KeyCodeDown) {
      this.currentIdx = this._nextIdx(remoteOptionContainers.length);
    }
    if (previousIdx !== -1) {
      remoteOptionContainers[previousIdx].classList.remove('current');
    }
    remoteOptionContainers[this.currentIdx].classList.add('current');
    this.showRemoteOptionList();
    this.textFieldTarget.value = remoteOptionContainers[this.currentIdx].dataset.display;
  }

  _selectCurrentRemoteOption = event => {
    event.preventDefault();
    this.remoteOptionListTarget.children[this.currentIdx].click();
  }

  _cancelRemoteOptionList = () => {
    this.hideRemoteOptionList();
    this.textFieldTarget.value = this.userInput;
    if (this.currentIdx > -1) {
      let currentRemoteOption = this.remoteOptionListTarget.children[this.currentIdx];
      currentRemoteOption.classList.remove('current');
      this.currentIdx = -1;
    }
  }

  _previousIdx = count => {
    let result = (this.currentIdx - 1) % count;
    if (result < 0) {
      result = count - 1;
    }
    return result;
  }

  _nextIdx = count => {
    return (this.currentIdx + 1) % count;
  }

  static get KeyEnter() { return 13; }
  static get KeyEscape() { return 27; }
  static get KeyCodeUp() { return 38; }
  static get KeyCodeDown() { return 40; }
}