import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  static targets = ['chart'];
  static values = {
    data: Array
  }

  connect() {
    if (!document.documentElement.hasAttribute("data-turbo-preview")) {
      this.drawChart();
    }
  }

  drawChart() {
    let ctx = this.chartTarget.getContext('2d');
    let ratios = []
    let tooltips = []
    let labels = []

    this.dataValue.forEach((datum) => {
      ratios.push(datum.ratio)
      tooltips.push(datum.reason)
      labels.push(moment(datum.date, 'YYYY-MM-DD').format('MM/DD/YY'))
    })

    new Chart(ctx, {
      type: 'line',
      data: {
        labels: labels,
        datasets: [{
          data: ratios,
          backgroundColor: 'rgba(0, 128, 128, 1)',
          borderColor: 'rgba(0, 128, 128, 1)',
          fill: false
        }]
      },
      options: {
        responsive: true,
        elements: {
          line: {
            tension: 0
          }
        },
        legend: {
          display: false
        },
        tooltips: {
          enabled: true,
          callbacks: {
            label: function (tooltipItem, data) {
              return tooltips[tooltipItem.index]
            }
          }
        },
        layout: {
          padding: {
            top: 5
          }
        },
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Referral Count',
              fontSize: 16,
              fontStyle: 'bold'
            },
            ticks: {
              beginAtZero: true,
              padding: 15,
              fontSize: 14,
              fontStyle: 'bold',
              callback: function (value) { if (value % 1 === 0) { return value; } },
              suggestedMax: this._buffer(ratios)
            }
          }],
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Week',
              fontSize: 16,
              fontStyle: 'bold'
            },
            ticks: {
              padding: 15,
              fontSize: 14,
              fontStyle: 'bold'
            }
          }]
        }
      }
    })
  }

  _buffer(values) {
    let maxValue = Math.max(...values)
    return maxValue > 0 ? maxValue * 1.2 : 1
  }
}
