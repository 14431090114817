import { Controller } from "@hotwired/stimulus";
import tippy from "tippy.js";
import 'tippy.js/dist/tippy.css';
import 'tippy.js/dist/border.css';

export default class extends Controller {
  static targets = ["element", "tooltipContent"];
  static values = {
    placement: { type: String, default: "top" },
    theme: { type: String, default: "lc-pink"}
  }

  connect() {
    this.elementTargets.forEach(el => {
      tippy(el, {
        theme: this.themeValue,
        placement: this.placementValue,
        content: this.tooltipContentTarget.innerHTML,
        allowHTML: true,
      });
    })
  }
}
