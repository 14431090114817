import { Controller } from "@hotwired/stimulus"

import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['wizard', 'form'];

  connect() {
    var step = window.location.hash.substr(1);

    var btnFinish = $("<button id='finish-btn'></button>")
      .text("Finish")
      .addClass("btn btn-info")
    if (step === 'step-1' || !step) {
      btnFinish.hide();
    }

    $(this.wizardTarget)
      .smartWizard({
        toolbarSettings: {
          toolbarExtraButtons: [btnFinish],
        },
      })
      .on(
        "showStep",
        function (e, anchorObject, stepNumber, stepDirection, stepPosition) {
          if (stepPosition === "first") {
            $("#prev-btn").addClass("disabled");
            $("#finish-btn").hide();
          } else if (stepPosition === "last") {
            $("#next-btn").hide();
            $("#finish-btn").show();
          } else {
            $("#finish-btn").hide();
            $("#next-btn").show();
            $("#prev-btn").removeClass("disabled");
          }
        }
      )
      .on(
        "leaveStep",
        (e, anchorObject, currentStepIndex, nextStepIndex, stepDirection) => {
          if (stepDirection === "forward") {
            if ($(this.formTarget).valid()) {
              return true;
            } else {
              return false;
            }
          }
        }
      );
  }
}
