import { Controller } from "@hotwired/stimulus";
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ["newLinkButton"]
  static values = {
    userId: String,
  }

  generate() {
    const data = new FormData();
    data.append("id", this.userIdValue)

    Rails.ajax({
      url: `/admin/external_users/${this.userIdValue}/create_reset_password_link`,
      type: 'POST',
      data: data,
      success: (res) => {
        this.newLinkButtonTarget.innerHTML = "Link copied to clipboard!"
        navigator.clipboard.writeText(`https://research.leapcure.com/users/password/edit?reset_password_token=${res.token}`);
      }
    })
  }
}
