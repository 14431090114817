import { Controller } from "@hotwired/stimulus"


export default class extends Controller {

  scroll(e) {
    const id = e.target.dataset.id;
    const element = document.getElementById(id);
    if (element) element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
}
