import { Controller } from "@hotwired/stimulus";
import Swal from "sweetalert2";
export default class extends Controller {
    showFlashNotifications(type, message) {
        Swal.fire({
            icon: type, // Type of notification (e.g., 'success', 'error', 'warning', 'info')
            title: message, // Message to display
            showConfirmButton: false, // Hide the confirmation button
            timer: 1500
        });

    }
}
