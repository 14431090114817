import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    $('.selectpicker').selectpicker("refresh");
    document.addEventListener('turbo:load', function() {
      $('.selectpicker').selectpicker();
    });
  }

  teardown() {
    $('.selectpicker').selectpicker('destroy').addClass('selectpicker');
  }

  showReasonWarning(event) {
    const reason = event.target.value;
    if (reason === "DNQ" || reason === "Not Interested") {
      document.querySelector('.reason-warning').classList.remove('d-none');
    } else {
      document.querySelector('.reason-warning').classList.add('d-none');
    }
  }
}
