import { Controller } from "@hotwired/stimulus";
import { notifySuccess, notifyFailure } from "src/notifications";

export default class extends Controller {
  static targets = ["form"];

  connect() {
    $(this.formTarget).on("ajax:success", function (event) {
      notifySuccess('Notes Updated');
    }).on("ajax:error", function (event) {
      notifyFailure('Could not save notes');
    });
  }

  disconnect() {
    $(this.formTarget).off("ajax:success").off("ajax:error");
  }
}
