import { $ } from "@rails/ujs";
import "core-js/stable";
import "regenerator-runtime/runtime";
import 'stylesheets/application';
import "@hotwired/turbo-rails"
import "preline/dist/preline.js"

require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");
require("controllers");
require("trix");
require("@rails/actiontext");

const images = require.context('../images', true);
const imagePath = (name) => images(name, true);

window.dispatchMapsEvent = function (...args) {
  const event = document.createEvent("Events");
  event.initEvent("google-maps-callback", true, true);
  event.args = args;
  window.dispatchEvent(event);
};
