import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  static targets = ['chart'];
  static values = {
    data: Object
  }

  connect() {
    if (!document.documentElement.hasAttribute("data-turbo-preview")) {
      this.drawChart();
    }
  }

  drawChart() {
    let ctx = this.chartTarget.getContext('2d');
    let labels = Object.keys(this.dataValue).map(date => moment(date, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YY'))

    new Chart(ctx, {
      type: 'line',
      data: {
        labels: labels,
        datasets: [{
          data: Object.values(this.dataValue),
          backgroundColor: 'rgba(0, 128, 128, 1)',
          borderColor: 'rgba(0, 128, 128, 1)',
          fill: false
        }]
      },
      options: {
        responsive: true,
        elements: {
          line: {
            tension: 0
          }
        },
        plugins: {
          legend: {
            display: false
          },
        },
        scales: {
          y: {
            title: {
              display: true,
              text: 'Referral Count',
              font: {
                size: 16,
                weight: 'bold'
              }
            },
            ticks: {
              beginAtZero: true,
              padding: 15,
              fontSize: 14,
              fontStyle: 'bold',
              callback: function (value) { if (value % 1 === 0) { return value; } },
              suggestedMax: this._buffer()
            }
          },
          x: {
            title: {
              display: true,
              text: 'Week',
              font: {
                size: 16,
                weight: 'bold'
              }
            },
            ticks: {
              padding: 15,
              font: {
                size: 14,
                weight: 'bold'
              }
            }
          }
        }
      }
    })
  }

  _buffer() {
    let maxValue = Math.max(...Object.values(this.dataValue))
    return maxValue > 0 ? maxValue * 1.2 : 1
  }
}
