import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    fieldId: String,
    text: String,
  };

  pasteText() {
    if (this.fieldIdValue && this.textValue) {
      let field = document.getElementById(this.fieldIdValue);
      if (field) {
        field.value = this.textValue;
      }
    }
  }
}
