import { Controller } from "@hotwired/stimulus"

import Handlebars from 'handlebars';

export default class extends Controller {
  static targets = ['usersInput'];
  static values = {
    url: String
  }

  disconnect() {
    $(this.usersInputTarget).tagsinput('destroy');
  }

  connect() {
    let users = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.whitespace,
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: `${this.urlValue}&search=%QUERY`,
        wildcard: '%QUERY',
        filter: function (data) {
          return $.map(data, function (user) {
            return { ...{ value: user.id, text: user.full_name }, ...user };
          });
        }
      }
    });

    $(this.usersInputTarget).tagsinput({
      itemValue: 'value',
      itemText: 'text',
      allowDuplicates: false,
      freeInput: false,
      typeaheadjs: [{
        hint: false,
        highlight: true,
        minLength: 1
      }, {
        displayKey: 'text',
        freeInput: false,
        limit: Infinity,
        name: 'users',
        source: users,
        templates: {
          empty: '<div class="tt-suggestion tt-no-result">No results found.</div>',
          suggestion: Handlebars.compile(`
            <div class="suggestion">
              <div class="user-name d-flex">{{full_name}} (<div class="mx-1">{{email}}</div>)</div>
            </div>
          `)
        }
      }]
    });
  }
}
