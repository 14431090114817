import { Controller } from "@hotwired/stimulus"

import consumer from '../channels/consumer';
import { Reflex } from "src/reflex";

export default class extends Controller {
  connect() {
    this.subscribeToUtmStatistics();
    this.reflex = new Reflex('UtmStatisticsReflex');
  }

  disconnect() {
    this.utmStatisticsChannel.unsubscribe();
    this.reflex.disconnect();
  }

  subscribeToUtmStatistics() {
    this.utmStatisticsChannel = consumer.subscriptions.create({
      channel: 'UtmStatisticsChannel'
    }, {
      received: function (data) {
        this.reflex.stimulate(data);
      }.bind(this)
    });
  }
}
