import "abortcontroller-polyfill/dist/abortcontroller-polyfill-only";
import { Controller } from "@hotwired/stimulus";
import { fetch } from "whatwg-fetch";

export default class extends Controller {
  static targets = ["textField", "postalCodeList"];
  static values = {
    url: String,
    value: String
  }

  search = (_event) => {
    this.userInput = this.textFieldTarget.value;
    this.currentIdx = -1;
    if (this.userInput !== "") {
      this._abortPreviousRequest();
      this.abortController = new AbortController();
      let url = this.urlValue + "&q=" + this.userInput + "&limit=10";
      this.abortableFetch(url, { signal: this.abortController.signal })
          .then((response) => response.text())
          .then((html) => this._updatePostalCodeList(html));
    } else {
      this._resetInput()
      this._updatePostalCodeList("");
    }
  };

  // Use native browser implementation if it supports aborting, otherwise
  // fall back to fetch polyfill.
  // https://github.com/github/fetch#aborting-requests
  abortableFetch = (url, options) => {
    const fn = "signal" in new Request("") ? window.fetch : fetch;
    return fn(url, options);
  };

  handleKeys = (event) => {
    if (this._isNavigationKey(event.keyCode)) {
      this._navigateInSearchResult(event.keyCode);
    } else if (event.keyCode === this.constructor.KeyEnter && this.currentIdx > -1 && this.selected === false) {
      this._selectCurrentPostalCode(event);
    } else if (event.keyCode === this.constructor.KeyEnter && this.currentIdx > -1 && this.selected === true) {
      this._submitPostalCode();
    } else if (event.keyCode === this.constructor.KeyEscape) {
      this._cancelPostalCodeList(event);
    }
  };

  postalCodeClicked = (event) => {
    const postal_code_value = JSON.parse(event.currentTarget.dataset.value)
    const { postal_code, latitude, longitude, city, country } = postal_code_value

    let latitude_el = document.getElementById('question_latitude');
    let longitude_el = document.getElementById('question_longitude');
    let city_el = document.getElementById('question_city');
    let country_el = document.getElementById('question_country');
    latitude_el.value = latitude
    longitude_el.value = longitude
    city_el.value = city
    country_el.value = country

    this.userInput = postal_code
    this.textFieldTarget.value = postal_code
    this.postalCodeListTarget.innerHTML = "";

    // enable button
    const button = document.getElementsByClassName('location-submit')[0];
    button.disabled = false;
    button.classList.add('btn-success')

    this.selected = true
  };

  showPostalCodeList = () => {
    this.selected = false
    this.postalCodeListTarget.classList.remove("hidden");
  }

  hidePostalCodeList = (event) => {
    if (this.currentIdx === -1) this.currentIdx = 0

    this._selectCurrentPostalCode(event);
    this.postalCodeListTarget.classList.add("hidden");
  }

  preventBlur = (mouseDownEvent) => mouseDownEvent.preventDefault();

  _submitPostalCode = () => {
    const button = document.getElementsByClassName('location-submit')[0];
    button.click()
  }

  _abortPreviousRequest = () => {
    if (this.abortController) {
      this.abortController.abort();
    }
  };

  _updatePostalCodeList = (html) => {
    this.postalCodeListTarget.innerHTML = html;
    this.showPostalCodeList();
  };

  _resetInput = () => {
    const button = document.getElementsByClassName('location-submit')[0];
    button.disabled = true;
    button.classList.remove('btn-success')
    let latitude_el = document.getElementById('question_latitude');
    let longitude_el = document.getElementById('question_longitude');
    latitude_el.value = ''
    longitude_el.value = ''
  }

  _isNavigationKey = (keyCode) => {
    return (
      keyCode === this.constructor.KeyCodeUp ||
      keyCode === this.constructor.KeyCodeDown
    );
  };

  _navigateInSearchResult = (keyCode) => {
    let postalCodeContainers = this.postalCodeListTarget.children;
    if (postalCodeContainers.length === 0) {
      return;
    }

    let previousIdx = this.currentIdx;
    if (keyCode === this.constructor.KeyCodeUp) {
      this.currentIdx = this._previousIdx(postalCodeContainers.length);
    } else if (keyCode === this.constructor.KeyCodeDown) {
      this.currentIdx = this._nextIdx(postalCodeContainers.length);
    }
    if (previousIdx !== -1) {
      postalCodeContainers[previousIdx].classList.remove("current");
    }
    postalCodeContainers[this.currentIdx].classList.add("current");
    this._scrollToSelected(this.postalCodeListTarget, postalCodeContainers[this.currentIdx])
    this.showPostalCodeList();
  };

  _selectCurrentPostalCode = (event) => {
    event.preventDefault();

    // don't try to autoselect if the user already clicked on an option and the dropdown is closed
    if (this.postalCodeListTarget.children.length >= this.currentIdx + 1) {
      this.postalCodeListTarget.children[this.currentIdx].click();
    }
  };

  _cancelPostalCodeList = (event) => {
    this.hidePostalCodeList(event);
    this.textFieldTarget.value = this.userInput;
    if (this.currentIdx > -1) {
      let currentPostalCode = this.postalCodeListTarget.children[this.currentIdx];
      currentPostalCode.classList.remove("current");
      this.currentIdx = -1;
    }
  };

  _previousIdx = (count) => {
    let result = (this.currentIdx - 1) % count;
    if (result < 0) {
      result = count - 1;
    }
    return result;
  };

  _nextIdx = (count) => {
    return (this.currentIdx + 1) % count;
  };

  _scrollToSelected = (list, selected) => {
    $(list).animate(
      { scrollTop: selected.offsetTop },
      150
    )
  }

  static get KeyEnter() {
    return 13;
  }
  static get KeyEscape() {
    return 27;
  }
  static get KeyCodeUp() {
    return 38;
  }
  static get KeyCodeDown() {
    return 40;
  }
}
