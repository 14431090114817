import { Controller } from "@hotwired/stimulus";
import { notifySuccess, notifyFailure } from "src/notifications";

export default class extends Controller {
  static values = {
    copyText: String
  }

  fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand('copy');
    } catch (err) {
      console.error('Fallback: Unable to copy', err);
    }

    document.body.removeChild(textArea);
  }

  copyTextToClipboard(text) {
    if (!navigator.clipboard) {
      this.fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text)
  }

  async copy(event) {
    event.preventDefault()
    this.copyTextToClipboard(this.copyTextValue)
    notifySuccess('URL copied!');
  }
}
