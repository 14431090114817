import { Controller } from "@hotwired/stimulus";
import tippy from "tippy.js";
import { Turbo } from "@hotwired/turbo-rails";
import TurboFlashController from "./turbo_flash_controller";


export default class extends Controller {
  static targets = ["content", "comment", "edit", "patientButton", "externalButton"];

  connect() {
    this.initializeTooltips();
  }

  initializeTooltips() {
    tippy('[data-tooltip-target="localTime"]', {
      content(reference) {
        // Use the convertToLocalTime method to set the tooltip content
        const utcTimeString = reference.dataset.utcTime;

        const utcDate = new Date(utcTimeString);
        const localTimeString = utcDate.toLocaleString();
        return localTimeString;
      },
      theme: "custom-theme",
      arrow: true,
      arrowType: "round",
      arrowTransform: "scale(1.5)",
      animation: "scale",
      duration: 300,
    });
  }

  toggleEdit(event) {
    this.commentTarget.classList.add("edit-comment-hidden");
    this.editTarget.classList.remove("edit-comment-hidden");
  }

  toggleHighlight(event) {
    const turboFlashController = new TurboFlashController();
    const isExternalField = document.getElementById("is_external_field");

    if (
      this.internalButtonTarget &&
      this.internalButtonTarget.classList.contains("btn-primary")
    ) {
      this.internalButtonTarget.classList.add("btn-secondary");
      this.internalButtonTarget.classList.remove("btn-primary", "disabled");
      this.externalButtonTarget.classList.add("btn-primary", "disabled");
      this.externalButtonTarget.classList.remove("btn-secondary");
      isExternalField.value = true;
      turboFlashController.showFlashNotifications("success", "Now showing external comments.");
    } else {
      this.internalButtonTarget.classList.add("btn-primary", "disabled");
      this.internalButtonTarget.classList.remove("btn-secondary");
      this.externalButtonTarget.classList.add("btn-secondary");
      this.externalButtonTarget.classList.remove("btn-primary", "disabled");
      isExternalField.value = false;
      turboFlashController.showFlashNotifications("success", "Now showing internal comments.");
    }
  }

  closeEdit() {
    this.commentTarget.classList.remove("edit-comment-hidden");
    this.editTarget.classList.add("edit-comment-hidden");
  }

  handleSuccess() {
    this.closeEdit();
  }


}
