import { Controller } from "@hotwired/stimulus";
import { createConsumer } from "@rails/actioncable"

export default class extends Controller {
  static values = { id: Number };
  static targets = [ "template", "viewerList" ];

  connect() {
    this.channel = createConsumer().subscriptions.create(
      {
        channel: "ScreenerAttemptViewersChannel",
        screener_attempt_id: this.idValue
      },
      {
        received: (data) => {
          this.viewerListTarget.innerHTML = "";
          data.viewers.forEach((viewer) => {
            const viewerEl = this.templateTarget.content.cloneNode(true);
            viewerEl.querySelector(".viewer-name").textContent = viewer.charAt(0);
            viewerEl.querySelector(".viewer-full-name").textContent = viewer;
            this.viewerListTarget.appendChild(viewerEl);
          })
        }
      }
    );
  }

  disconnect() {
    this.channel.unsubscribe();
  }
}