import { Controller } from "@hotwired/stimulus"


export const chartStore = {
  statsChart: undefined,
  get chart() {
    return this.statsChart;
  },
  set chart(newChart) {
    this.statsChart = newChart;
  }
};

export default class BaseUtmSource extends Controller {
  static values = {
    data: Object
  }

  drawChart(chartTarget, dataSets) {
    let ctx = chartTarget.getContext('2d');

    let newChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: ['Prescreened', 'Completed', 'Qualified', 'Referred'],
        datasets: dataSets
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: 'y',
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: true,
            callbacks: {
              title: function (context) {
                let total = context[0].dataset.data[0];
                let count = context[0].dataset.data[context[0].dataIndex];
                if (count === 0) return '0%';
                return `${Math.max(1, Math.ceil(count / total * 100))}%`;
              },
              label: function(context) {
                return `${context.label}: ${context.raw}`
              }
            }
          },
        },
        scales: {
          y: {
            ticks: {
              font: {
                weight: 'bold'
              }
            }
          },
          x: {
            beginAtZero: true,
            ticks: {
              userCallback: function (label) {
                if (Math.floor(label) === label) {
                  return label;
                }
              },
              font: {
                weight: 'bold'
              }
            }
          }
        }
      }
    });
    chartStore.chart = newChart;

    this.changeTitle();
  }

  changeTitle() {
    const counts = this.dataValue;
    document.getElementById('selected-utm-source-title').innerText = `${counts.name} (${counts.prescreened})`;
  }

  datasets() {
    const counts = this.dataValue;

    return [{
      label: counts.name,
      data: [counts.prescreened, counts.completed, counts.qualified, counts.referred],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)'
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)'
      ],
      borderWidth: 1
    }];
  }
}
