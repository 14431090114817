import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";
import {
  notifySuccess,
  notifyInfo,
  notifyFailure,
  clearNotifications,
} from "src/notifications";
import _ from "lodash";
import moment from "moment";

export default class AutosaveController extends Controller {
  static targets = ["form", "verbalConfirmationDate"];
  static values = {
    duration: Number,
    hideAutosaveNotifications: Boolean,
  };

  connect() {
    window.notificationShown = false;
    document.addEventListener('turbo:frame-load', this.handleTurboFrameLoad);

    // Initialize debounce version of form submit
    this._debouncedFormSubmit = _.debounce(() => {
      this._submitForm();
      this.unsavedChanges = false;
    }, this.duration);

    this._debouncedNotify = _.debounce(
      () => {
        notifyInfo("Saving...");
      },
      this.duration,
      { leading: true }
    );
  }

  disconnect() {
    window.notificationShown = false;
    $(this.formTarget).off("ajax:success").off("ajax:error");
  }

  handleTurboFrameLoad() {
    if (!window.notificationShown) {
      notifySuccess("Saved!");

      window.notificationShown = true;
    }
  }

  save(event) {
    this.eventType = event && event.type;
    this.unsavedChanges = true;

    let alertDiv = $("div.alert");
    const noNotificationsPresent = alertDiv.length === 0;
    const successOrErrorPresent =
      alertDiv.length > 0 && alertDiv[0].className.indexOf("alert-info") === -1;
    if (noNotificationsPresent || successOrErrorPresent) {
      clearNotifications();
      if (!this.hideAutosaveNotificationsValue) {
        this._debouncedNotify();
      }
    }

    this._debouncedFormSubmit();
  }

  setVerbalConfirmationDate(event) {
    let checkbox = event.target;
    let verbalConfirmation = this.verbalConfirmationDateTarget;
    if (checkbox && verbalConfirmation) {
      if (checkbox.checked) {
        let dateText = moment().format("MMM DD, YYYY - h:mm A");
        verbalConfirmation.innerText = dateText;
      } else {
        verbalConfirmation.innerText = "";
      }
    }
  }

  success() {
    this.unsavedChanges = false;
    setTimeout(() => {
      clearNotifications();
      if (
        (this.hideAutosaveNotificationsValue && this.eventType == "click") ||
        !this.hideAutosaveNotificationsValue
      ) {
        notifySuccess("Saved!");
      }
    }, 500);
  }

  //if we get an error, we need to return it.
  error(e) {
    this.unsavedChanges = false;
    setTimeout(() => {
      let [data, status, xhr] = e.detail;
      let message = JSON.parse(xhr.response).errors;
      clearNotifications();
      if (
        (this.hideAutosaveNotificationsValue && this.eventType == "click") ||
        !this.hideAutosaveNotificationsValue
      ) {
        notifyFailure("Unable to save. Please try again. " + message);
      }
    }, 500);
  }

  leavingPage(event) {
    if (this.unsavedChanges) this._submitForm();
  }

  _submitForm() {
    Rails.fire(this.formTarget, "submit");
  }

  get duration() {
    return this.durationValue || 500;
  }
}
